<template>
  <div id="checkinform">
    <h2>请在下方填写您的基本信息</h2>
    <div style="text-align: center;"> 如有输入困难，可只输入电话号码<br>前台会给您致电以收集所需信息</div>
    <h3 style="color:red; text-align: center;">紧急情况请直接拨打911!</h3>
    <form id="intake">
      姓名：
      <input type="text" v-model="name" required>
      生日：<br>
      <input type="date" v-model="bdate" required><br>
      家庭住址：
      <input type="text" v-model="home_address">
      <br>
      联系电话：
      <input type="text" v-model="phone">
      <br>
      首次就诊？<input type="checkbox" v-model="is_first_visit">
      性别：
      男
      <input type="radio" name="gender" value="male" v-model="gender">
      女
      <input type="radio" name="gender" value="female" v-model="gender">
      其他
      <input type="radio" name="gender" value="other" v-model="gender">
      <br>
      保险信息：
      <select v-model="insurance">
        <option value=0>无医疗保险，自费就诊</option>
        <option value=1>有医疗保险，使用保险就诊</option>
      </select>
      <div v-if="insurance==1">
        <br>
        请输入保险公司名称：
        <input type="text" v-model="insurance_name">
        请输入保险号码：
        <input type="text" v-model="insurance_id">
      </div>
      请简单描述您的症状：<br>
      <textarea v-model="symptoms" rows="4" cols="50"></textarea>
    </form>
    <button @click="formSubmit">确定</button>
  </div>
</template>

<script>
  /* eslint-disable no-useless-escape*/
  import router from '@/router'
  import {
    Cache
  } from 'aws-amplify'

  export default {
    name: 'CheckInFormComp',
    components: {
      Cache
    },
    props: {
      id: {
        type: String,
        default: "notset"
      }
    },
    data() {
      return {
        name: null,
        bdate: null,
        gender: null,
        phone: null,
        is_first_visit: null,
        insurance: null,
        home_address: null,
        scriptLoaded: false,
        insurance_name: null,
        insurance_id: null,
        symptoms: null
      }
    },
    methods: {
      formSubmit: function () {
        this.saveInput2Cache()

        router.push({
          name: "ConfirmConsent"
        })


      },
      saveInput2Cache: function () {
        this.name ? Cache.setItem('name', this.name) : Cache.setItem('name', 'not-provided')
        this.bdate ? Cache.setItem('bdate', this.bdate) : Cache.setItem('bdate', 'not-provided')
        this.gender ? Cache.setItem('gender', this.gender) : Cache.setItem('gender', 'not-provided')
        Cache.setItem('phone', this.phone)
        this.is_first_visit ? Cache.setItem('is_first_visit', this.name) : Cache.setItem('is_first_visit', 'not-provided')
        this.home_address ? Cache.setItem('home_address', this.home_address) : Cache.setItem('home_address', 'not-provided')
        this.symptoms ? Cache.setItem('symptoms', this.symptoms) : Cache.setItem('symptoms', 'not-provided')
        this.insurance ? Cache.setItem('insurance', this.insurance) : Cache.setItem('insurance', 'not-provided')
        this.insurance_name ? Cache.setItem('insurance_name', this.insurance_id) : Cache.setItem('insurance_name', 'not-provided')
        this.insurance_id ? Cache.setItem('insurance_id', this.insurance_name) : Cache.setItem('insurance_id', 'not-provided')
      },
      check4cache: function(){
        var session_id = Cache.getItem("session_id");
        if(session_id != null){
          console.log("Session ID exist in cache, attempting to reload.")
          router.push({
              name: "WaitingRoom"
            })
        }
        else{
          Cache.clear()
        }
      }
    },
    created() {
      console.log(Cache.getItem('progress'))
    },
    mounted() {
      this.check4cache()
    }
  }
</script>

<style scoped>
  #checkinform {
    padding: 30px;
    margin: 40px auto;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    font-family: sans-serif;
  }


  @media screen and (min-width: 600px) {
    #checkinform {
      width: 600px;
    }
  }

  h2 {
    background: #1bb1dc;
    padding: 20px 30px 15px 45px;
    margin: -30px -30px 30px -30px;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
    border: 1px solid #1bb1dc;
    font-family: sans-serif;
  }

  #intake {
    display: block;
    font: 16px;
    color: rgb(46, 46, 46);
    margin-bottom: 15px;
    padding: 15px;
  }


  input {
    padding: 8px 0px 8px 8px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #777777;
  }

  input[type="text"] {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ccc;
  }

  input[type="checkbox"] {
    margin-right: 30px;
  }

  textarea,
  select {
    background: rgba(255, 255, 255, .1);
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 18px;
    margin: 0;
    outline: 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: #777777;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    margin-bottom: 10px;
  }

  textarea {
    background-color: #e8eeef;
  }

  button {
    background-color: #1bb1dc;
    /* Green */
    border: none;
    border-radius: 8px;
    color: white;
    padding: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-left: 15px;
  }

  button:hover {
    background: #1587a7;
  }
</style>