<template>
  <div id="app">
    <div id="nav">
      <div class="NavBar">
        <header id="header">
          <div class="container">
            <div class="logo float-left">
              <a href="https://drwang.care/zh-hans/index.html" class="scrollto"><img src="@/assets/img/Logo-Smalll.png"
                  alt="王雨林家庭医生诊所" class="img-fluid" style="height: 50px; width:auto;"></a>
                  <h1 style="text-align: center; color:#1bb1dc ">COVID-19 线上医疗 (测试版)</h1>
            </div>
          </div>
        </header><!-- #header -->
      </div>
      <div class="navContainer preload">
        <nav v-show="$router.history.current['path'] !=='/doc/login'">
          <router-link class="routerLinkButton" style="pointer-events: auto" event="disable" to="/"><i
              class="fas fa-notes-medical fa-3x"></i><span>在线挂号</span></router-link>
          <router-link class="routerLinkButton" style="pointer-events: auto" event="disable" to="/visit/confirm-consent"><i
              class="fas fa-calendar-check fa-3x"></i><span>确认信息</span></router-link>
          <router-link class="routerLinkButton" style="pointer-events: auto" event="disable" to="/visit/waiting-room"><i
              class="fas fa-hourglass-half fa-3x"></i><span>预诊/候诊</span></router-link>
          <router-link class="routerLinkButton" style="pointer-events: auto" event="disable" to="/test4"><i
              class="fas fa-laptop-medical fa-3x"></i><span>准备连线</span></router-link>
          <router-link class="routerLinkButton" style="pointer-events: auto" event="disable" to="/test5"><i
              class="fas fa-comment-medical fa-3x"></i><span>王医生视频连线</span></router-link>
        </nav>
      </div>
    </div>
    <router-view />
  </div>



</template>


<script>
  import $ from 'jquery'

  import {
    AmplifyEventBus
  } from 'aws-amplify-vue'
  import {
    Cache
  } from 'aws-amplify'

  export default {
    name: 'app',
    components: {
      AmplifyEventBus,
      Cache
    },

    mounted() {

      $("a").removeClass('router-link-exact-active');
          var index = $(this).index('nav a') + 1;
          $("nav a:nth-child(-n+" + index + ")").addClass('router-link-exact-active');
          
      // $(document).ready(function () {
      //   $("a").click(function () {
          
      //   });

      // });

      $(window).on('load',function () {
        $(".container").addClass("load");
      });

      AmplifyEventBus.$on('authState', info => {
        console.log(`Here is the auth event that was just emitted by an Amplify component: ${info}`)
      });
      Cache.setItem('progress', '0')
    }
  }
</script>


<style scoped>
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.navContainer {
  margin-top: 100px;
  margin-bottom: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  font-family: sans-serif;
}

.navContainer h1 {
  position: relative;
  font-size: 2.5em;
  margin: 1.5em auto 3em;
  color: #dd2791;
  text-transform: uppercase;
}

.navContainer h1:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  margin: .25em 0 0;
  height: 2px;
  width: 0;
  background: #dd2791;
  -webkit-transition: width 0.5s cubic-bezier(1, 0, 0.5, 1.01);
  transition: width 0.5s cubic-bezier(1, 0, 0.5, 1.01);
}

.navContainer.load h1:after {
  width: 100%;
}

.navContainer nav {
  position: relative;
  width: 600px;
  margin: 0 auto auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navContainer nav .routerLinkButton {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-decoration: none;
  color: #1bb1dc;
  text-align: center;
}

.navContainer nav .routerLinkButton i {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-bottom: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: opacity .3s ease-out, bottom .5s ease;
  transition: opacity .3s ease-out, bottom .5s ease;
}

.navContainer nav .routerLinkButton > i {
  opacity: 1;
  bottom: 100%;
}

.navContainer nav .routerLinkButton.router-link-exact-active > i {
  color: #dd2791;
  opacity: 1;
  bottom: 100%;
  -webkit-animation-name: blinkfade;
          animation-name: blinkfade;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes blinkfade {
  0% {
    color: #dd2791;
  }
  50% {
    color: #ffd3ed;
  }
  100% {
    color: #dd2791;
  }
}

@keyframes blinkfade {
  0% {
    color: #dd2791;
  }
  50% {
    color: #ffd3ed;
  }
  100% {
    color: #dd2791;
  }
}

.navContainer nav .routerLinkButton span {
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  width: 80px;
  margin-top: 20px;
  opacity: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: opacity .3s ease-out, bottom .5s ease;
  transition: opacity .3s ease-out, bottom .5s ease;
}

.navContainer nav .routerLinkButton > span {
  opacity: 1;
  top: 100%;
}

.navContainer nav .routerLinkButton.router-link-active > span {
  color: #dd2791;
  opacity: 1;
  top: 100%;
}

.navContainer nav .routerLinkButton:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 60px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.navContainer nav .routerLinkButton:before {
  content: '';
  z-index: 99;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: auto;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 4px #1bb1dc;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 4px #1bb1dc;
  background: #ffffff;
  -webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.75, 0, 0.24, 1);
  transition: -webkit-box-shadow 0.3s cubic-bezier(0.75, 0, 0.24, 1);
  transition: box-shadow 0.3s cubic-bezier(0.75, 0, 0.24, 1);
  transition: box-shadow 0.3s cubic-bezier(0.75, 0, 0.24, 1), -webkit-box-shadow 0.3s cubic-bezier(0.75, 0, 0.24, 1);
}

.navContainer nav .routerLinkButton:not(.router-link-active):before {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 5px #1bb1dc;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 5px #1bb1dc;
}

.navContainer nav .routerLinkButton.router-link-active:before {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 10px #dd2791;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 10px #dd2791;
}

.navContainer nav .routerLinkButton:first-child.router-link-active ~ .line {
  width: 0;
}

.navContainer nav .routerLinkButton:nth-child(2).router-link-active ~ .line {
  width: 34%;
}

.navContainer nav .routerLinkButton:nth-child(3).router-link-active ~ .line {
  width: 66%;
}

.navContainer nav .routerLinkButton:nth-child(4).router-link-active ~ .line {
  width: 100%;
}

.navContainer nav .line {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: -1;
  width: 0;
  height: 2px;
  background: #dd2791;
  -webkit-transition: width 0.5s cubic-bezier(1, 0, 0.5, 1.01);
  transition: width 0.5s cubic-bezier(1, 0, 0.5, 1.01);
}
/*# sourceMappingURL=project3.css.map */
</style>