<template>
  <div class="checkin">
  <CheckInForm></CheckInForm>
  
  </div>
</template>

<script>

// @ is an alias to /src
import CheckInForm from '@/components/checkin/checkInForm.vue'
// import { Auth, API, graphqlOperation } from 'aws-amplify'

export default {
  name: 'CheckInView',
  components: {
    CheckInForm
  }
}
</script>
