// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:36e15992-8ff9-42da-a74e-97f2093bc21e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_AUPReJnc8",
    "aws_user_pools_web_client_id": "6fg2qgeum503ldnugn0du5dri1",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://54hinwyn75f63nho6yoruhi6x4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-smy2c62berapdo5dx3iitsetqi"
};


export default awsmobile;
