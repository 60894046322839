import Vue from 'vue'
import VueRouter from 'vue-router'
import CheckInView from '../views/CheckInView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'CheckIn',
    component: CheckInView
  },
  {
    path: '/visit',
    name: 'Visit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VisitView.vue')
  },
  {
    path: '/visit/waiting-room',
    name: 'WaitingRoom',
    component: () => import('../views/WaitingRoomView.vue')
  },

  {
    path: '/visit/confirm-consent',
    name: 'ConfirmConsent',
    component: () => import('../views/ConfirmConsentView.vue')
  },

  {
    path: '/doc/login',
    name: 'SignIn',
    component: () => import('../views/docViews/SignInView.vue')
  },
  {
    path:'visit/ready-to-connect',
    name:'Video',
    component: () => import('../components/video/video.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
